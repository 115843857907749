import DevTools from './components/DevTools';
import Routes from './routes';

function App() {
  return (
    <>
      <div className="content">
        <Routes />
      </div>
      { process.env.REACT_APP_DEV_MODE === "true"
        && <DevTools />
      }
    </>
  );
}

export default App;
