import Logo from '../assets/images/BeepBot-Logo-hardhat.svg';
import styles from './Error.module.scss';

interface ErrorProps {
  code: number;
}

const Error = ({ code }: ErrorProps) => {
  return <div className={ styles.root }>
    <h1>Error { code }</h1>
    <p>looks like you hit a snag!</p>
    <br />
    <img src={ Logo } height={200} alt="" />
  </div>;
};

export default Error;