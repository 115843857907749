export const eventTypes = [
	'Followed',
	'Hosted',
	'Raided',
	'Dropped Bits',
	'Subscribed',
	'Re-Subscribed',
	'Gifted a Subscription',
	'Donated',
];
