import { useRoutes } from 'react-router-dom';

import Error from './pages/Error';
import Index from './pages/Index';

export const RoutesList = (website?: any) => ([
    /**
     * AUTHED ROUTES
     */
    {
        element: <Index layout="center" />,
        exact: true,
        path: '/',
    },
    {
        element: <Index layout="start" />,
        exact: true,
        path: '/left',
    },
    {
        element: <Index layout="end" />,
        exact: true,
        path: '/right',
    },

    /**
     * WEBSITE SELECTOR
     */
    {
        element: <Error code={404} />,
        path: '/*'
    },
]);

export default function Routes() {
    const routes = useRoutes(RoutesList());

    return routes;
};
