import clsx from 'clsx';
import { find, matches, random } from 'lodash';
import { useEffect, useState } from 'react';
import { io } from 'socket.io-client';
import { adjectives, animals, colors, uniqueNamesGenerator } from 'unique-names-generator';

import logoNewStatic from '../assets/images/android-chrome-512x512.png';
import logoAnimated from '../assets/images/Heart-Logo.gif';
import logoStatic from '../assets/images/Heart-Logo.png';
import { eventTypes } from '../libs/store';
import styles from './Index.module.scss';

interface PageProps {
  layout: "center" | "start" | "end" | undefined;
}

interface IData {
  data: {
    content?: string;
  },
  type: string;
  trigger: string;
}

interface IAudio {
  audio?: HTMLAudioElement;
  duration: number;
}

interface IMessage {
  delay: number;
}

const delay = (ms: number) => new Promise(resolve => setTimeout(resolve, ms));
const queue: any[] = [];
const protection: any[] = [];
const protectAgainst = ['follow', 'host'];

let BeepBotAPI = io('https://api.synerate.com');

const rooms = [
  '9314901:overlays' /* tlove */,
  ...(process.env.REACT_APP_DEV_MODE === "true" ? ['5921894:overlays'] : []) /* mrv (dev mode only) */,
];

const Index = ({ layout }: PageProps) => {
  const [pulse, setPulse] = useState<Boolean | null>(null);
  const [active, setActive] = useState<Boolean | null>(null);
  const [rotate, setRotate] = useState<Boolean>(false);

  useEffect(() => {
    BeepBotAPI.on('connect', () => {
      console.log('Connected to the Synerate API');

      setTimeout(() =>
        BeepBotAPI.emit('join', {
          rooms
        }),
        1500
      );

      runCheck();
    });

    BeepBotAPI.on('join-room', (room, id) => {
      console.log('Connected to room:', room, id);
    })

    for ( const room of rooms ) {
      BeepBotAPI.on(room, (e: any /* add typing for this later */) => {
        addToQueue(e);

        console.log('Channel Action received from BeepBot API', e);
      });
    }

    BeepBotAPI.on('disconnect', () => {
      console.log('Disconnected from the Synerate API');
    });

    return () => {
      BeepBotAPI.disconnect();
    };
  }, []);

  const addToQueue = (data: IData[]) => {
    console.info(`[ADD]`, queue.length, data);
    queue.push(data);
  };

  const addToProtection = (data: IData[]) => {
    let $return = false;

    for (const d of data) {
      if ( !protectAgainst.includes(d.trigger) ) $return = true;
    }

    if ( $return ) return;

    console.info(`[SPAM PROTECTION]`, queue.length, data);
    protection.push(data);
  };

  const runNext = async () => {
    if (queue.length > 0) {
      const next = queue[0];

      console.log(!!find(protection, matches(next)));

      if ( !!find(protection, matches(next)) ) {
        console.log('Event already ran');
        
        queue.shift();
        runNext();

        return;
      }

      addToProtection(next);

      let durations: number[] = [];

      setPulse(true);

      await delay(1000);

      for ( const n of next ) {
        const { delay: messageDelay } = handleAlert(n);
        const { audio, duration: audioDelay }: IAudio = await handleAudio(n);
  
        audio?.play();

        durations.push( messageDelay, audioDelay );
      }

      console.log(`[next]`, next, ...durations);

      if ( durations[0] !== 0) {
        setActive(true);
      }

      const finalDelay = Math.max(...durations);
      
      console.log('[show]', finalDelay);

      await delay(finalDelay);

      setActive(false);
      console.log('[hide]');

      await delay(250);

      setPulse(false);

      queue.shift();
      runNext();
    } else {
      runCheck();
    }
  };

  const runCheck = () => {
    const inter = setInterval(() => {
      if ( queue.length > 0 ) {
        clearInterval(inter);
        runNext();
      }
    }, 100);
  };

  const handleAudio = async (e: any): Promise<IAudio> => {
    if( e.type !== 'audio' ) return { duration: 0 };
    
    const audio = new Audio(e.data.content);

    const duration: number = await (new Promise((resolve, reject) => {
      audio.onloadedmetadata = () => {
        resolve( Number( (e.data.delay || audio.duration) * 1000) ); // return in ms
      };
      setTimeout(() => reject('audio took too long to load metadata'), 1000);
    }));

    return {
      audio,
      duration,
    }
  };

  const handleAlert = (e: any): IMessage => {
    console.log(e);
    if( e.type !== 'message' ) return { delay: 0 };

    return {
      delay: e.data.delay * 1000 || 5000,
    };
  };

  return (
    <div className={ styles.root }>
      <div
        className={ styles.widget }
        style={{
          left: layout === "start"
            ? "20px"
            : layout === "end"
              ? "calc(100% - 20px)"
              : "50%",
          transform: `translateX(${
            layout === "start"
              ? '0'
              : layout === "end"
                ? '-100%'
                : '-50%'
          })`
        }}
      >
        <img
          src={ pulse ? logoAnimated : logoStatic }
          alt="logo static"
          className={ clsx(styles.logo, {  }) }
          onClick={ () => {
            const type = eventTypes[random(0, eventTypes.length-1)];

            addToQueue([{
              data: {
                content: 'https://cdn.syner.at/files/5919436/169523c70d610d7499c7402888079397.mp3',
              },
              type: 'audio',
              trigger: type
            }, {
              data: {
                content: `${uniqueNamesGenerator({
                  dictionaries: [adjectives, colors, animals],
                  separator: '',
                  style: 'capital',
                })} ${type}`
              },
              type: 'message',
              trigger: type
            }])
          } }
        />
        <div
          className={ clsx(styles.blob, {
            [styles[layout || 'center']]: true,
            [styles.blobInactive]: active === false,
            [styles.blobActive]: active && active !== false,
          }) }
        >
          <div className={ styles.blobInner }>
            <h3>{ (queue[0] as IData[])?.filter(d => d.type === 'message').map(d => d.data.content).join('\n') }</h3>
          </div>
        </div>
      </div>

      {/* filters */}
      <svg xmlns="http://www.w3.org/2000/svg" version="1.1">
        <defs>
          <filter id="shadowed-goo">
            <feGaussianBlur in="SourceGraphic" result="blur" stdDeviation="10" />
            <feColorMatrix in="blur" mode="matrix" values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 18 -7" result="goo" />
            <feGaussianBlur in="goo" stdDeviation="3" result="shadow" />
            <feColorMatrix in="shadow" mode="matrix" values="0 0 0 0 0  0 0 0 0 0  0 0 0 0 0  0 0 0 1 -0.2" result="shadow" />
            <feOffset in="shadow" dx="1" dy="1" result="shadow" />
            <feBlend in2="shadow" in="goo" result="goo" />
            <feBlend in2="goo" in="SourceGraphic" result="mix" />
          </filter>
          <filter id="goo">
            <feGaussianBlur in="SourceGraphic" result="blur" stdDeviation="10" />
            <feColorMatrix in="blur" mode="matrix" values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 18 -7" result="goo" />
            <feBlend in2="goo" in="SourceGraphic" result="mix" />
          </filter>
        </defs>
      </svg>
    </div>
  );
}

export default Index;